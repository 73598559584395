import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/autocomplete';

const PredictiveProviderMain = (() => {
    let config = {},
        dictionary = {},
        selectedWithEnter = false;

    // read the taxonomy from the page. This is configurable
    const getPageTermIds = () => {
        let pageTermIds = [],
            taxonomy = Window.VitalSite;

        if (taxonomy !== undefined) {
            taxonomy = Window.VitalSite.taxonomy;
        } else {
            taxonomy = VitalSite.taxonomy;
        }

        // get taxonomy term and facet id's from page source.
        // must assign these id's via Administration Group in Taxonomy Config to "Pages"
        $(taxonomy).each((i, tax) => {
            $(tax.Terms).each((j, term) => {
                pageTermIds.push(term.TermId);
            });
        });
        return pageTermIds.join();
    };

    // get results from api
    const getNameList = (successCallback) => {
        window.$.getProviderNamesList({
            SearchTermIds: config.usePageTermId ? getPageTermIds() : '',
            version: 2
        }, (data) => {
            successCallback(data);
        });
    };
    const getQueryStringObject = (data) => {
        var pairs;
        var queryStringObject = {};
        var terms = [];

        // Replace all the webform generated cruft around the input IDs
        data = data.replace(/VsMasterPage%24MainContent%24AdvancedSearchFields%24searchForm%24/g, '').replace(/%24InternalTextBox/g, '').replace(/%24InternalDropDownList/g, '').replace(/%24hiddenRadiusField/g, '').replace(/%24RadiusList/g, '');

        // Parse query string into object for easier manipulation
        pairs = data.split('&');
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            queryStringObject[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        // Time to fix the query string object to match what we need on the search results page.
        for (const property in queryStringObject) {
            // Fix cities
            if (property === 'cities') {
                if (queryStringObject[property].toLowerCase() !== 'any city') {
                    queryStringObject['city'] = queryStringObject[property];
                }

                delete queryStringObject[property];
            }

            if (property === 'UserLat') {
                queryStringObject['lat'] = queryStringObject[property];
                delete queryStringObject[property];
            }

            if (property === 'UserLng') {
                queryStringObject['lng'] = queryStringObject[property];
                delete queryStringObject[property];
            }

            if (property === 'UserPosition') {
                queryStringObject['userEnteredPosition'] = queryStringObject[property];
                delete queryStringObject[property];
            }

            // If term, add to terms array;
            if (property.startsWith('TermDropDownListByFacetContainer')) {
                terms.push(queryStringObject[property]);
                delete queryStringObject[property];
            }

            // Fix Gender
            if (property === 'gender') {
                switch (queryStringObject[property]) {
                    case '1':
                        queryStringObject[property] = 'Male';
                        break;
                    case '2':
                        queryStringObject[property] = 'Female';
                        break;
                    default:
                        delete queryStringObject[property];
                }
            }
        };

        // Add additional query string parameters
        if (terms.length > 0) {
            queryStringObject['termId'] = terms.join(',');
        }

        let newInputValue = $(config.newInput).val();

        if (newInputValue.length > 0 && config.singleInput.length === 0) {
            if (dictionary[newInputValue] !== undefined) {
                queryStringObject['first'] = dictionary[newInputValue].FirstName;
                queryStringObject['last'] = dictionary[newInputValue].LastName;
            } else {
                queryStringObject[config.searchByWithEnterKey] = newInputValue;
            }
        }

        if ($.isEmptyObject(queryStringObject)) {
            queryStringObject['ignore'] = 'true';
        }

        queryStringObject['navigationNode'] = window.Geonetric.Page.NavigationNode; // Needed for the CMS request portion.
        queryStringObject['sort'] = 7; // Best Match sort by default

        return queryStringObject
    }
    const doSearch = () => {
        var data = $(config.advancedFormInputs).filter(function () {
            return this.value !== '' && this.value !== '00000000-0000-0000-0000-000000000000';
        }).serialize();
        var queryStringObject = getQueryStringObject(data);
        window.location = `/app/doctors/results.aspx?${$.param(queryStringObject)}`;
    };

    // defaults
    config = {
        advancedFormInputs: '#ProviderAdvancedSearch :input',
        submitBtn: '#ProviderAdvancedSearch .ButtonHolder .Button',
        firstInput: '.PhysicianFirst',
        lastInput: '.PhysicianLast',
        singleInput: '',
        minLength: 2,
        delay: 300,
        usePageTermId: true,
        enterKeyToSelect: true,
        enterKeyToSubmit: true,
        searchByWithEnterKey: 'keyword', // first/last/keyword
        newInput: '#ProviderNameTextBox',
        newInputHTML(newInput) {
            return `<div class="ProviderName">
            <label for="${newInput.replace(/[#.]/, '')}">Provider Name</label>
            <input type="text" ${newInput.includes('#') ? 'id=' : 'class='}"${newInput.replace(/[#.]/, '')}" maxlength="100">
        </div>`;
        }
    };

    // main function
    const initialize = (options) => {
        $.extend(config, options);

        if (config.singleInput.length === 0) {
            $(config.firstInput).before(config.newInputHTML(config.newInput));
            $(config.firstInput + ',' + config.lastInput).hide().find('input').val('');
        } else {
            config.newInput = config.singleInput;
        }

        getNameList((data) => {
            // if testNames is passed in we'll use that instead of the api results
            config.testNames ? data = config.testNames : '';

            data.map((el) => {
                dictionary[`${el.FirstName} ${el.LastName}`] = el;
            });
            const keys = $.map(dictionary, (obj) => {
                return `${obj.FirstName} ${obj.LastName}`;
            });

            $(config.newInput).autocomplete({
                source: keys,
                delay: config.delay,
                minLength: config.minLength
            });
        });
    };

    $(document).on('keydown', config.newInput, (event) => {
        // the Enter key has been pressed
        if (event.which === 13 || event.keyCode === 13) {
            event.preventDefault();

            if (config.enterKeyToSelect) {
                // if the autocomplete box is showing and the user hits "enter", select the first result and search on that
                if ($('ul.ui-autocomplete.ui-front').is(':visible')) {
                    $('ul.ui-autocomplete.ui-front li:first').click();
                    selectedWithEnter = true;
                }
            }

            if (config.enterKeyToSubmit && !selectedWithEnter && !$('ul.ui-autocomplete.ui-front').is(':visible')) {
                doSearch();
                return;
            }
            selectedWithEnter = false;
        }
    });

    $(config.submitBtn).click((e) => {
        e.preventDefault();
        doSearch();
    });

    return {
        config: config,
        initialize: initialize,
        getPageTermIds: getPageTermIds,
        getNameList: getNameList,
        getQueryStringObject: getQueryStringObject,
        doSearch: doSearch
    };
})();

export default PredictiveProviderMain;
